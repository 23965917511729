<template>
  <div class="row" style="margin: 0;">
    <div class="col-md-6">
      <div class="row" style="margin: 0; align-items: end; margin-top: 5px;">
        <div class="form-group col-md-6">
          <label for="operarios">Operarios:</label>
          <select v-model="operarioseleccionado" class="form-control" id="operarios">
            <option value="-1">Seleccione operario</option>
            <option value="">Todos</option>
            <option v-for="operario in operarios" :key="operario.id" :value="operario.id">{{ operario.nombre }}</option>
          </select>
        </div>
        <div class="form-group col-md-2">
          <label for="operarios">Guardia:</label>
          <select v-model="tiposeleccionado" class="form-control" id="tipos">
            <option value="">Todas</option>
            <option value="1">Mostrar solo guardias</option>
            <option value="0">Ocultar guardias</option>
          </select>
        </div>
        <div class="col-md-4 form-group">
          <label for="operarios">Especialidad:</label>
          <select :disabled="this.operarioseleccionado!=''" v-model="especialidadseleccionada" class="form-control" id="especialidades">
            <option value="">Todas</option>
            <option v-for="especialidad in especialdiades" :key="especialidad.id" :value="especialidad.id">{{ especialidad.nombre }}</option>
          </select>
        </div>
      </div>
      <div class="table-responsive" style="height: 600px;">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Operario</th>
              <th>Nombre</th>
              <th>Kms</th>
              <th>Guardia</th>
              <th>Eliminar</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="cobertura in coberturas" :key="cobertura.id">
              <th>{{ cobertura.nombre }}</th>
              <th>{{ cobertura.nombre_cobertura }}</th>
              <th>{{ cobertura.kilometros_cobetura }}</th>
              <th><input @click="cambiarGuardia(cobertura)" type="checkbox" v-model="cobertura.guardia" v-bind:true-value="1" v-bind:false-value="0"></th>
              <th>
                <button class="btn btn-sm btn-danger" title="Eliminar" @click="eliminarCobertura(cobertura.id)"><i class="fas fa-trash-alt"></i></button>            
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-6">
      <div id="map" style="height: 100%;"></div>
    </div>
  </div>
     
</template>

<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';

export default({
  data() {
    return {
      coordenadas_iniciales:[],
      operarios: [],
      operarioseleccionado: '-1',
      tiposeleccionado: '',
      especialdiades: [],
      especialidadseleccionada: '',
      map: null,
      drawControl: null,
      drawnItems: null,
      coberturas: [], // Aquí se almacenarán las coberturas que recibas
    }
  },
  methods: {
    async cambiarGuardia(cobertura){
      const api = new PwgsApi();
      await api.put('coberturas-operarios/'+cobertura.id,{guardia: cobertura.guardia!=1?'true':'false'});
    },
    async eliminarCobertura(idcobertura){
      const api = new PwgsApi();
      await api.delete('/coberturas-operarios/'+idcobertura);
      this.cargarCoberturas();
    },
    cargarMapa() {
      if (!this.map) {
        console.log('coordsinics',this.coordenadas_iniciales);
        
        if (this.coordenadas_iniciales.length==0){
          this.map = L.map('map').setView([40.48038142908172,-4.081025004330084], 10);
        }else{
          this.map = L.map('map').setView(this.coordenadas_iniciales, 10);
        }
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);
        this.drawnItems = new L.FeatureGroup();
        this.map.addLayer(this.drawnItems);
        this.map.on(L.Draw.Event.CREATED, async (event) => {
        const layer = event.layer;
        this.drawnItems.addLayer(layer);
        let tipo = '';
        let data = {};
        if (layer instanceof L.Circle) {
          // Si es un círculo
          tipo = 'circular';
          const latlng = layer.getLatLng();
          const radius = layer.getRadius();
          
          // Crear el cuerpo del POST para un círculo
          data = {
            tipo: tipo,
            nombre: "Cobertura circular "+latlng.lat+','+latlng.lng, // Puedes cambiar este nombre o pedir que el usuario lo ingrese
            id_operario: this.operarioseleccionado, // Operario seleccionado
            radio: radius / 1000, // Convertimos a kilómetros si lo necesitas
            latitud: latlng.lat,
            longitud: latlng.lng,            
          };
          if(this.tiposeleccionado==1)data.guardia=1;

        } else if (layer instanceof L.Polygon) {
          // Si es un polígono
          tipo = 'poligonal';
          const latlngs = layer.getLatLngs()[0]; // Obtenemos las coordenadas del polígono

          // Convertimos las coordenadas a un array de strings "lat,lng"
          const poligono = latlngs.map(latlng => `${latlng.lat},${latlng.lng}`);
          const fecha = Date.now();
          const hoy = new Date(fecha);
          // Crear el cuerpo del POST para un polígono
          data = {
            tipo: tipo,
            nombre: "Cobertura poligonal "+hoy.toLocaleDateString(), // Puedes cambiar este nombre o pedir que el usuario lo ingrese
            id_operario: this.operarioseleccionado, // Operario seleccionado
            poligono: poligono,            
          };
          if(this.tiposeleccionado==1)data.guardia=1;
        }

        // Realizamos la solicitud POST
        if (data.tipo) {
          try {
            const api = new PwgsApi();
            await api.post('/coberturas-operarios', data);
            console.log('Cobertura creada con éxito:', data);

            // Recargar las coberturas después de crear una nueva
            this.cargarCoberturas();
          } catch (error) {
            console.error('Error al crear la cobertura:', error);
          }
        }
      });
      }
      this.actualizarControlesDeDibujo();
      this.mostrarCoberturas();
    },
    actualizarControlesDeDibujo() {
      if (this.drawControl) {
        this.map.removeControl(this.drawControl);
      }

      this.drawControl = new L.Control.Draw({
        edit: {
          featureGroup: this.drawnItems,
          remove: false,
          edit:false
        },
        draw: {
          polyline: false,
          rectangle: false,
          marker: false,
          circlemarker: false,
          polygon: this.operarioseleccionado > 0,
          circle: this.operarioseleccionado > 0
        }
      });

      this.map.addControl(this.drawControl);
    },
    mostrarCoberturas() {
      this.drawnItems.clearLayers(); // Limpiar cualquier forma existente
      if(this.coberturas == []){
        console.log('vuelve');
        return;
      }
      console.log('siguess', this.coberturas);
      this.coberturas.forEach(cobertura => {
        var color = 'blue';
        if(cobertura.guardia == 1){
          color = 'red';
        }
        if (cobertura.tipo_cobertura === 'circular') {
          const lat = parseFloat(cobertura.latitud_cobertura);
          const lng = parseFloat(cobertura.longitud_cobetura);
          const radius = parseFloat(cobertura.kilometros_cobetura) * 1000; // Convertir kilómetros a metros

          // Validar las coordenadas antes de crear el círculo
          if (!isNaN(lat) && !isNaN(lng) && !isNaN(radius)) {
            const circle = L.circle([lat, lng], {
              radius: radius,
              color: color,  // Color del borde
              fillColor: color,  // Color de relleno
              fillOpacity: 0.1,  // Opacidad del relleno (0.0 a 1.0)
              opacity: 0.5  // Opacidad del borde (0.0 a 1.0)
            });
            this.drawnItems.addLayer(circle);
          } else {
            console.error('Coordenadas inválidas para cobertura circular:', cobertura);
          }
        } else if (cobertura.tipo_cobertura === 'poligonal') {
          const latLngs = cobertura.poligono_cobertura.split(';').map(coord => {
            const [lat, lng] = coord.split(',').map(Number);
            return (!isNaN(lat) && !isNaN(lng)) ? [lat, lng] : null;
          }).filter(Boolean); // Filtrar valores nulos o inválidos

          if (latLngs.length > 0) {
            const polygon = L.polygon(latLngs, {
              color: color,  // Color del borde
              fillColor: color,  // Color de relleno
              fillOpacity: 0.1,  // Opacidad del relleno (0.0 a 1.0)
              opacity: 0.5  // Opacidad del borde (0.0 a 1.0)
            });
            this.drawnItems.addLayer(polygon);
          } else {
            console.error('Coordenadas inválidas para cobertura poligonal:', cobertura);
          }
        }
      });
    },
    async cargarOperarios() {
      const api = new PwgsApi();
      const res = await api.get('operarios/simple?sortField=nombre&sortOrder=1');
      this.operarios = res.datos;
    },
    async cargarEspecialidades() {
      const api = new PwgsApi();
      const res = await api.get('especialidades');
      this.especialdiades = res.datos;
    },
    async cargarCoberturas() {
      if(this.operarioseleccionado != -1){
        const api = new PwgsApi();
        var body ={id_operario:this.operarioseleccionado, id_especialidad:this.especialidadseleccionada};
        if(this.tiposeleccionado)body.guardia=this.tiposeleccionado;
        const res = await api.post('coberturas-operarios/coberturas', body);
        this.coordenadas_iniciales = res.coordenadas;
        this.coberturas = res.coberturas.datos;
      }
      else{
        this.coberturas = [];
      }
      if(this.coberturas != []){
        this.coberturas.sort((a, b) => {
          if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) {
            return -1;
          }
          if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      }
      console.log('coberturasss', this.coberturas);
      this.cargarMapa();
    }
  },
  mounted() {
    this.cargarCoberturas(); // Cargar y mostrar las coberturas existentes    
    this.cargarOperarios();
    this.cargarEspecialidades();
  },
  watch: {
    operarioseleccionado(newValue) {
      if(newValue!='')this.especialidadseleccionada='';
      this.cargarCoberturas();
    },
    especialidadseleccionada(){
      this.cargarCoberturas();
    },
    tiposeleccionado(){
      this.cargarCoberturas();
    }
  }
});
</script>

<style>
#map { height: 500px; }
</style>
